import { Box, Button, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
// theme
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import theme from "../../theme";
// local components
import Link from "../link";
import TextField from "../text-field";

const propTypes = {};
const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    display: "flex",
    justifyContent: "center",
  },
  box: {
    width: "400px",
    marginTop: theme.spacing(3),
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  form: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(3),
  },
  links: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(1),
  },
  link: {},
}));
const AdminResetComponent = ({ status = "", goToSignIn, goToSignUp, sendEmailStatus, onSubmit }) => {
  const classes = useStyles(theme);
  const [email, setEmail] = useState("");
  const handleInputChange =
    () =>
    ({ target }) =>
      setEmail(target.value);
  const handleSubmit = () => {
    onSubmit(email);
  };

  const { i18n } = useLingui();

  return (
    <>
      <Container className={classes.container}>
        <Box className={classes.box}>
          {(sendEmailStatus === "initial" || sendEmailStatus === "loading") && (
            <>
              <Typography variant="h3" uppercase>
                <Trans>BIENVENIDO ADMINISTRADOR</Trans>
              </Typography>
              <Box mt={1 / 2}>
                <Typography variant="body1">
                  <Trans>Ingresa tu mail para reiniciar la contraseña y poder ingresar a la plataforma</Trans>
                </Typography>
              </Box>
              <ValidatorForm onSubmit={handleSubmit} className={classes.form}>
                <Box display="flex" flexDirection="column">
                  <TextField
                    label=""
                    placeholder={`${i18n._(t`E-mail`)}`}
                    value={email}
                    onChange={handleInputChange("email")}
                    validators={["required", "isEmail"]}
                    errorMessages={[`${i18n._(t`Necesitamos un e-mail`)}`, `${i18n._(t`El e-mail no es válido`)}`]}
                    fullWidth
                  />
                </Box>
                {sendEmailStatus === "error" && (
                  <Typography variant="body1" color="error">
                    {`${i18n._(t`Lo sentimos, hubo un error al procesar tu solicitud, vuelve a intentar por favor.`)}`}
                  </Typography>
                )}
                <Box className={classes.buttonContainer}>
                  <Button
                    disabled={sendEmailStatus === "loading"}
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    fullWidth
                  >
                    <Trans>Reiniciar contraseña</Trans>
                    {sendEmailStatus === "loading" && "..."}
                  </Button>
                </Box>
              </ValidatorForm>
              <Typography variant="body1">
                <Box className={classes.links}>
                  <Link onClick={goToSignUp} className={classes.link}>
                    <Trans>¿No tienes cuenta?</Trans>
                  </Link>
                  <Box marginX={1} fontWeight="bold">
                    |
                  </Box>
                  <Link onClick={goToSignIn} className={classes.link}>
                    <Trans>No recuerdo mi contraseña</Trans>
                  </Link>
                </Box>
              </Typography>
            </>
          )}
          {sendEmailStatus === "success" && (
            <>
              <Typography variant="h3" uppercase>
                <Trans>¿Te has olvidado tu contraseña?</Trans>
              </Typography>
              <Box mt={2}>
                <Typography variant="h6">
                  <Trans>Se envió el correo electrónico con el enlace para restablecer la contraseña</Trans>
                </Typography>
              </Box>
            </>
          )}
          {sendEmailStatus === "error" && (
            <>
              <Typography variant="h3" uppercase>
                <Trans>¿Te has olvidado tu contraseña?</Trans>
              </Typography>
              <Box mt={2}>
                <Typography variant="h6">
                  <Trans>Ocurrió un problema, inténtelo de nuevo.</Trans>
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Container>
    </>
  );
};

AdminResetComponent.propTypes = propTypes;

export default AdminResetComponent;
