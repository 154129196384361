import React, { useState } from "react";
import AdminResetComponent from "../../components/forgotpass/admin-reset";
import AuthApi from "../../api/auth";
import { useSelector } from "react-redux";

const ForgotPass = ({
  status = "",
  goToSignIn,
  goToSignUp,
}) => {
  const [sendEmailStatus, setSendEmailStatus] = useState("initial");

  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const userStatus = useSelector((state) => state.user.status);

  const handleSendPasswordResetEmail = async (email) => {
    setSendEmailStatus("loading");
    await AuthApi.sendEmailResetPassword(email);
    setSendEmailStatus("success");
  };

  return (
    <>
      {!isAuthenticated && userStatus !== "loading" && (
        <AdminResetComponent
          status={status}
          goToSignIn={goToSignIn}
          goToSignUp={goToSignUp}
          sendEmailStatus={sendEmailStatus}
          onSubmit={handleSendPasswordResetEmail}
        />
      )}
    </>
  );
};

export default ForgotPass;
